import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://thecolonelsbequestremakeimages.s3.us-west-2.amazonaws.com/remake-game-cover.jpg" alt="The Colonel's Bequest Remake" />
      </header>
    </div>
  );
}

export default App;
